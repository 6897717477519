import { gql } from 'graphql-request'

export const getClient = gql`
    query getWebformClient($code: String!) {
        webformClient(code: $code) {
            data {
                code
                name
                birth_date
            }
        }
    }
`

export const getClientCounterReference = gql`
    query getWebformClientCounterReference($code: String!) {
        webformClientCounterReference(code: $code) {
            data {
                code
                name
                birth_date
                status
                restricted_network_code
            }
        }
    }
`

export const getProvider = gql`
    query getWebformProvider($code: String!) {
        webformProvider(code: $code) {
            data {
                code
                name
                specialty
                crm
                ufcr
                regional_council
            }
        }
    }
`